<template>
  <v-card elevation="1">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="members"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, -1],
        }"
        :options.sync="pagination"
        :server-items-length="pagination.itemCount"
        class="elevation-0"
        item-key="id"
        @contextmenu:row="showContextMenu"
        @click:row="navigateTo"
      >
        <template v-slot:item.isActive="{ item }">
          <!-- <v-btn tile icon @click="deactivateUser(item)"> -->
          <v-icon dense :color="item.isActive ? 'green' : 'red'">{{
            item.isActive ? "mdi-check" : "mdi-cancel"
          }}</v-icon>
          <!-- </v-btn> -->
        </template>
      </v-data-table>
    </v-card-text>

    <context-menu
      v-model="contextMenu.showMenu"
      :items="contextMenuItems"
      :x="contextMenu.x"
      :y="contextMenu.y"
    ></context-menu>
    <!-- <v-menu
      v-model="contextMenu.showMenu"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item v-for="(item, index) in contextMenuItems" :key="index">
          <v-list-item-title
            class="pointer"
            @click="item.action"
            v-if="item.action != null"
            >{{ item.title }}</v-list-item-title
          >
          <v-list-item-title class="pointer" v-else>{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-card>
</template>
<script>
import roleMixin from "../../mixins/role";
import ContextMenu from "../../components/Shared/ContextMenu.vue";
import * as Routes from "../../router/route-names";
export default {
  components: { ContextMenu },
  mixins: [roleMixin],
  data() {
    return {
      contextMenu: {
        showMenu: false,
        x: 0,
        y: 0,
        selectedItem: null,
      },
      // Skip only first load... for reasons
      skipped: false,
    };
  },
  computed: {
    members() {
      return this.$store.getters["user/users"];
    },
    pagination: {
      get() {
        //var d = this.paginationData;
        var d = this.$store.getters["user/pagination"];
        //console.log(d);
        return d;
      },
      set(val) {
        // this.paginationData = val;
        // this.loadUsersList(this.paginationData);
        // console.log("Set");
        this.loadUsersList(val);
        this.$store.commit("user/SET_PAGINATION", val);
      },
    },
    contextMenuItem() {
      return this.contextMenu.selectedItem;
    },
    contextMenuItems() {
      if (this.isUserSudo) {
        var items = [];

        if (this.contextMenuItem != null) {
          var item = this.contextMenuItem.item;
          items = [
            {
              title: item.isActive
                ? this.$t("page.userList.actions.deactivate")
                : this.$t("page.userList.actions.activate"),
              action: () => {
                item.isActive = !item.isActive;
                this.updateUser(item);
              },
            },
          ];
          var roles = this.$store.getters["utils/roles"];
          items.push({
            title: this.$t("page.userList.actions.changeRole"),
            children: roles.map((x) => {
              return {
                title: x.name,
                action: () => {
                  item.role = x;
                  this.updateUser(item);
                },
                disabled: x.id == item.role.id,
              };
            }),
          });
          if (process.env.NODE_ENV != "developpement")
            items.push({
              title: "Log",
              action: () => {
                console.log(item);
              },
            });
        }
        return items;
      }
      return [];
    },
    headers() {
      var h = [];
      h.push({
        text: this.$t("page.userList.headers.username"),
        value: "username",
      });
      h.push({ text: this.$t("page.userList.headers.email"), value: "email" });

      if (this.isInRole("Sudo", "Moderator"))
        h.push({
          text: this.$t("page.userList.headers.role"),
          value: "role.name",
        });
      if (this.isInRole("Sudo"))
        h.push({
          text: this.$t("page.userList.headers.isActive"),
          value: "isActive",
        });
      //   h.push({
      //     text: this.$t("page.userList.headers.action"),
      //     value: "actions",
      //     sortable: false,
      //   });
      return h;
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler(val, old) {
        var query = this.$route.query;
        var params = { page: val.page, itemsPerPage: val.itemsPerPage };
        this.$router
          .replace({
            //name: Routes.USER_LIST,
            query: { ...query, ...params },
          })
          .catch((e) => {});
        //this.loadUsersList(val);
      },
    },
  },
  methods: {
    updateUser(user) {
      this.$store.dispatch("user/updateUser", { ...user });
    },
    // editUser(e, user) {
    //   e.preventDefault();
    //   this.$store.dispatch("notification/addModal", {
    //     componentName: "user-dialog",
    //     parameter: { user },
    //     width: 500,
    //   });
    // },
    navigateTo(e, row) {
      var item = row.item;
      this.$router.push({
        name: Routes.USER_PROFILE,
        params: { userId: item.publicId },
      });
    },
    showContextMenu(e, item) {
      e.preventDefault();
      this.contextMenu.showMenu = false;
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;
      this.contextMenu.selectedItem = item;

      this.$nextTick(() => {
        this.contextMenu.showMenu = true;
      });
    },
    log(e) {
      console.log(e);
    },
    loadUsersList(pagination) {
      this.$store.dispatch("user/loadUsers", { ...pagination });
    },
  },
  created() {
    this.$store.dispatch("utils/loadRoles", {});
  },
};
</script>